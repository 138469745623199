<template>
  <div class="icc__block">
    <div class="icc__block-body">
      <div class="icc__block-container icc__block-overflow pb-4 mb-6">
        <div class="icc__block-row">
          <div class="icc__block- text">
            <div class="d-flex mb-8 mb-md-4">
              <div>
                <div class="icc__block-title icc__block-title_with-icon">
                  Результаты проведенного лечения
                </div>
                <div
                  class="
                    d-xl-block d-flex
                    direction-md-row direction-column-reverse
                  "
                >
                  <div class="text-1 mb-6 mb-sm-4" v-html="blocks.block1">
                    
                  </div>
                  <div class="ml-3 d-xl-none mx-sm-auto mb-sm-4">
                    <img
                      :srcset="`${require('@/assets/img/interactive-clinical-case/step23__img.png')} 2x`"
                    />
                  </div>
                </div>
              </div>
              <div class="ml-3 d-xl-block d-none">
                <img
                  :srcset="`${require('@/assets/img/interactive-clinical-case/step23__img.png')} 2x`"
                />
              </div>
            </div>
            <div class="d-xl-flex mb-4">
              <div class="mr-4 mr-md-0 mb-md-4" style="min-width: 40%">
                <div class="text-1" v-html="blocks.block2">
                  
                </div>
              </div>
              <div class="ml-4 ml-md-0">
                <div class="text-1" v-html="blocks.block3">
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="icc__block-steps">
      <Steps
        @prevStep="$emit('prevStep')"
        @nextStep="$emit('nextStep')"
        :stepsCount="18"
        :activeStep="17"
      />
    </div>
  </div>
</template>

<script>
import Steps from "@/components/interactiveClinicalCase/Steps.vue";

export default {
  name: "ICCStep23",
  components: {
    Steps,
  },
  props: {
    step: Object,
    question1: Object,
    question2: Object,
    question3: Object,
  },
  data: () => ({}),
  computed: {
    questionAnswers() {
      return {
        condition1: this.question1.choices.find((c) => c.is_selected) ? this.question1.choices.find((c) => c.is_selected).slug : 'test13_1_1',
        condition2: this.question2.choices.find((c) => c.is_selected) ? this.question2.choices.find((c) => c.is_selected).slug : 'test21_1_1',
        condition3: this.question3.choices.find((c) => c.is_selected) ? this.question3.choices.find((c) => c.is_selected).slug : 'test19_1_1',
      };
    },
    blocks() {
      return {
        block1: this.results[this.questionAnswers.condition1][this.questionAnswers.condition2][this.questionAnswers.condition3].block1,
        block2: this.results[this.questionAnswers.condition1][this.questionAnswers.condition2][this.questionAnswers.condition3].block2,
        block3: this.results[this.questionAnswers.condition1][this.questionAnswers.condition2][this.questionAnswers.condition3].block3,
      }
    },
    results() {
      return {
        // Антиаритмическая и ритмурежающая терапия не требуется
        test13_1_1: {
          // Продолжить прием Омега-3 кислот этиловый эфир
          test21_1_1: {
            // Не требуется
            test19_1_1: {
              block1: `Через несколько дней после коррекции терапи пациентка вызвала домой бригаду скорой медицинской помощи в связи с вновь возникшим частым аритмичным пульсов. Пациентка также отмечала колющие боли в левой половине грудной клетки, наросла одышка, появилась слабость. `,
              block2: `При осмотре: АД 90/50 мм рт.ст., ЧСС 100-110 в мин, аритмичный пульс.<br>
                        При госпитализации NT – proBNP – 890 пг/мл `,
              block3: `
              На ЭКГ: Фибрилляция предсердий с частотой желудочковых сокращений 108-115 уд/мин. Неполная блокада правой ножки пучка Гиса. 
              `,
            },
            // Оральный антикоагулянт
            test19_1_2: {
              block1: `Через несколько дней после коррекции терапи пациентка вызвала домой бригаду скорой медицинской помощи в связи с вновь возникшим частым аритмичным пульсов. Пациентка также отмечала колющие боли в левой половине грудной клетки, наросла одышка, появилась слабость. `,
              block2: `При осмотре: АД 90/50 мм рт.ст., ЧСС 100-110 в мин, аритмичный пульс. <br>
                        При госпитализации NT – proBNP – 890 пг/мл `,
              block3: `
              На ЭКГ: Фибрилляция предсердий с частотой желудочковых сокращений 108-115 уд/мин. Неполная блокада правой ножки пучка Гиса.  
              `,
            },
            // Антиагрегантная терапия
            test19_1_3: {
              block1: `Через несколько дней после коррекции терапи пациентка вызвала домой бригаду скорой медицинской помощи в связи с вновь возникшим частым аритмичным пульсов. Пациентка также отмечала колющие боли в левой половине грудной клетки, наросла одышка, появилась слабость. `,
              block2: `При осмотре: АД 90/50 мм рт.ст., ЧСС 100-110 в мин, аритмичный пульс. <br>
                        При госпитализации NT – proBNP – 890 пг/мл `,
              block3: `
              На ЭКГ: Фибрилляция предсердий с частотой желудочковых сокращений 108-115 уд/мин. Неполная блокада правой ножки пучка Гиса.  
              `,
            },
            // Требуется двойная терапия (антиагрегант + антикоагулянт)
            test19_1_4: {
              block1: `Через несколько дней после коррекции терапи пациентка вызвала домой бригаду скорой медицинской помощи в связи с вновь возникшим частым аритмичным пульсов. Пациентка также отмечала колющие боли в левой половине грудной клетки, наросла одышка, появилась слабость. `,
              block2: `При осмотре: АД 90/50 мм рт.ст., ЧСС 100-110 в мин, аритмичный пульс. <br>
                        При госпитализации NT – proBNP – 890 пг/мл `,
              block3: `
              На ЭКГ: Фибрилляция предсердий с частотой желудочковых сокращений 108-115 уд/мин. Неполная блокада правой ножки пучка Гиса.  
              `,
            },
          },
          // Аторвастатин
          test21_1_2: {
            // Не требуется
            test19_1_1: {
              block1: `Через несколько дней после коррекции терапи пациентка вызвала домой бригаду скорой медицинской помощи в связи с вновь возникшим частым аритмичным пульсов. Пациентка также отмечала колющие боли в левой половине грудной клетки, наросла одышка, появилась слабость.`,
              block2: `При осмотре: АД 90/50 мм рт.ст., ЧСС 100-110 в мин, аритмичный пульс. 
                        <br>
                        При госпитализации NT – proBNP – 890 пг/мл `,
              block3: `
              На ЭКГ: Фибрилляция предсердий с частотой желудочковых сокращений 108-115 уд/мин. Неполная блокада правой ножки пучка Гиса. 
              `,
            },
            // Оральный антикоагулянт
            test19_1_2: {
              block1: `Через несколько дней после коррекции терапи пациентка вызвала домой бригаду скорой медицинской помощи в связи с вновь возникшим частым аритмичным пульсов. Пациентка также отмечала колющие боли в левой половине грудной клетки, наросла одышка, появилась слабость. `,
              block2: `При осмотре: АД 90/50 мм рт.ст., ЧСС 100-110 в мин, аритмичный пульс. 
                        <br>
                        При госпитализации NT – proBNP – 890 пг/мл `,
              block3: `
              На ЭКГ: Фибрилляция предсердий с частотой желудочковых сокращений 108-115 уд/мин. Неполная блокада правой ножки пучка Гиса.  
              `,
            },
            // Антиагрегантная терапия
            test19_1_3: {
              block1: `Через несколько дней после коррекции терапи пациентка вызвала домой бригаду скорой медицинской помощи в связи с вновь возникшим частым аритмичным пульсов. Пациентка также отмечала колющие боли в левой половине грудной клетки, наросла одышка, появилась слабость.`,
              block2: `При осмотре: АД 90/50 мм рт.ст., ЧСС 100-110 в мин, аритмичный пульс. 
                        <br>
                        При госпитализации NT – proBNP – 890 пг/мл `,
              block3: `
              На ЭКГ: Фибрилляция предсердий с частотой желудочковых сокращений 108-115 уд/мин. Неполная блокада правой ножки пучка Гиса. 
              `,
            },
            // Требуется двойная терапия (антиагрегант + антикоагулянт)
            test19_1_4: {
              block1: `Через несколько дней после коррекции терапи пациентка вызвала домой бригаду скорой медицинской помощи в связи с вновь возникшим частым аритмичным пульсов. Пациентка также отмечала колющие боли в левой половине грудной клетки, наросла одышка, появилась слабость.  `,
              block2: `При осмотре: АД 90/50 мм рт.ст., ЧСС 100-110 в мин, аритмичный пульс. <br>
                        При госпитализации NT – proBNP – 890 пг/мл `,
              block3: `
             На ЭКГ: Фибрилляция предсердий с частотой желудочковых сокращений 108-115 уд/мин. Неполная блокада правой ножки пучка Гиса. 
              `,
            },
          },
          // Крестор
          test21_1_3: {
            // Не требуется
            test19_1_1: {
              block1: `Через несколько дней после коррекции терапи пациентка вызвала домой бригаду скорой медицинской помощи в связи с вновь возникшим частым аритмичным пульсов. Пациентка также отмечала колющие боли в левой половине грудной клетки, наросла одышка, появилась слабость. `,
              block2: `При осмотре: АД 90/50 мм рт.ст., ЧСС 100-110 в мин, аритмичный пульс. 
                        <br>
                        При госпитализации NT – proBNP – 890 пг/мл`,
              block3: `
              На ЭКГ: Фибрилляция предсердий с частотой желудочковых сокращений 108-115 уд/мин. Неполная блокада правой ножки пучка Гиса.  
              `,
            },
            // Оральный антикоагулянт
            test19_1_2: {
              block1: `Через несколько дней после коррекции терапи пациентка вызвала домой бригаду скорой медицинской помощи в связи с вновь возникшим частым аритмичным пульсов. Пациентка также отмечала колющие боли в левой половине грудной клетки, наросла одышка, появилась слабость. `,
              block2: `При осмотре: АД 90/50 мм рт.ст., ЧСС 100-110 в мин, аритмичный пульс. 
                        <br>
                        При госпитализации NT – proBNP – 890 пг/мл `,
              block3: `
              На ЭКГ: Фибрилляция предсердий с частотой желудочковых сокращений 108-115 уд/мин. Неполная блокада правой ножки пучка Гиса. 
              `,
            },
            // Антиагрегантная терапия
            test19_1_3: {
              block1: `Через несколько дней после коррекции терапи пациентка вызвала домой бригаду скорой медицинской помощи в связи с вновь возникшим частым аритмичным пульсов. Пациентка также отмечала колющие боли в левой половине грудной клетки, наросла одышка, появилась слабость. `,
              block2: `При осмотре: АД 90/50 мм рт.ст., ЧСС 100-110 в мин, аритмичный пульс. 
                        <br>
                        При госпитализации NT – proBNP – 890 пг/мл`,
              block3: `
              На ЭКГ: Фибрилляция предсердий с частотой желудочковых сокращений 108-115 уд/мин. Неполная блокада правой ножки пучка Гиса.  
              `,
            },
            // Требуется двойная терапия (антиагрегант + антикоагулянт)
            test19_1_4: {
              block1: `Через несколько дней после коррекции терапи пациентка вызвала домой бригаду скорой медицинской помощи в связи с вновь возникшим частым аритмичным пульсов. Пациентка также отмечала колющие боли в левой половине грудной клетки, наросла одышка, появилась слабость. `,
              block2: `При осмотре: АД 90/50 мм рт.ст., ЧСС 100-110 в мин, аритмичный пульс. <br>
                        При госпитализации NT – proBNP – 890 пг/мл `,
              block3: `
             На ЭКГ: Фибрилляция предсердий с частотой желудочковых сокращений 108-115 уд/мин. Неполная блокада правой ножки пучка Гиса.
              `,
            },
          },
          // Эзетимиб
          test21_1_4: {
            // Не требуется
            test19_1_1: {
              block1: `Через несколько дней после коррекции терапи пациентка вызвала домой бригаду скорой медицинской помощи в связи с вновь возникшим частым аритмичным пульсов. Пациентка также отмечала колющие боли в левой половине грудной клетки, наросла одышка, появилась слабость. `,
              block2: `При осмотре: АД 90/50 мм рт.ст., ЧСС 100-110 в мин, аритмичный пульс. 

                        <br>
                        При госпитализации NT – proBNP – 890 пг/мл  `,
              block3: `
              На ЭКГ: Фибрилляция предсердий с частотой желудочковых сокращений 108-115 уд/мин. Неполная блокада правой ножки пучка Гиса. 
              `,
            },
            // Оральный антикоагулянт
            test19_1_2: {
              block1: `Через несколько дней после коррекции терапи пациентка вызвала домой бригаду скорой медицинской помощи в связи с вновь возникшим частым аритмичным пульсов. Пациентка также отмечала колющие боли в левой половине грудной клетки, наросла одышка, появилась слабость.  `,
              block2: `При осмотре: АД 90/50 мм рт.ст., ЧСС 100-110 в мин, аритмичный пульс. 

                        <br>
                        При госпитализации NT – proBNP – 890 пг/мл `,
              block3: `
              На ЭКГ: Фибрилляция предсердий с частотой желудочковых сокращений 108-115 уд/мин. Неполная блокада правой ножки пучка Гиса.  
              `,
            },
            // Антиагрегантная терапия
            test19_1_3: {
              block1: `Через несколько дней после коррекции терапи пациентка вызвала домой бригаду скорой медицинской помощи в связи с вновь возникшим частым аритмичным пульсов. Пациентка также отмечала колющие боли в левой половине грудной клетки, наросла одышка, появилась слабость. `,
              block2: `При осмотре: АД 90/50 мм рт.ст., ЧСС 100-110 в мин, аритмичный пульс. 

                        <br>
                        При госпитализации NT – proBNP – 890 пг/мл  `,
              block3: `
              На ЭКГ: Фибрилляция предсердий с частотой желудочковых сокращений 108-115 уд/мин. Неполная блокада правой ножки пучка Гиса. 
              `,
            },
            // Требуется двойная терапия (антиагрегант + антикоагулянт)
            test19_1_4: {
              block1: `Через несколько дней после коррекции терапи пациентка вызвала домой бригаду скорой медицинской помощи в связи с вновь возникшим частым аритмичным пульсов. Пациентка также отмечала колющие боли в левой половине грудной клетки, наросла одышка, появилась слабость. `,
              block2: `При осмотре: АД 90/50 мм рт.ст., ЧСС 100-110 в мин, аритмичный пульс. 
                    <br>
                        При госпитализации NT – proBNP – 890 пг/мл `,
              block3: `
             На ЭКГ: Фибрилляция предсердий с частотой желудочковых сокращений 108-115 уд/мин. Неполная блокада правой ножки пучка Гиса. 
              `,
            },
          },
          // Эзетимиб + Статин
          test21_1_5: {
            // Не требуется
            test19_1_1: {
              block1: `Через несколько дней после коррекции терапи пациентка вызвала домой бригаду скорой медицинской помощи в связи с вновь возникшим частым аритмичным пульсов. Пациентка также отмечала колющие боли в левой половине грудной клетки, наросла одышка, появилась слабость. `,
              block2: `При осмотре: АД 90/50 мм рт.ст., ЧСС 100-110 в мин, аритмичный пульс. 

                        <br>
                        При госпитализации NT – proBNP – 890 пг/мл `,
              block3: `
              На ЭКГ: Фибрилляция предсердий с частотой желудочковых сокращений 108-115 уд/мин. Неполная блокада правой ножки пучка Гиса. 
              `,
            },
            // Оральный антикоагулянт
            test19_1_2: {
              block1: `Через несколько дней после коррекции терапи пациентка вызвала домой бригаду скорой медицинской помощи в связи с вновь возникшим частым аритмичным пульсов. Пациентка также отмечала колющие боли в левой половине грудной клетки, наросла одышка, появилась слабость.`,
              block2: `При осмотре: АД 90/50 мм рт.ст., ЧСС 100-110 в мин, аритмичный пульс. 
                        <br>
                        При госпитализации NT – proBNP – 890 пг/мл `,
              block3: `
              На ЭКГ: Фибрилляция предсердий с частотой желудочковых сокращений 108-115 уд/мин. Неполная блокада правой ножки пучка Гиса. 
              `,
            },
            // Антиагрегантная терапия
            test19_1_3: {
              block1: `Через несколько дней после коррекции терапи пациентка вызвала домой бригаду скорой медицинской помощи в связи с вновь возникшим частым аритмичным пульсов. Пациентка также отмечала колющие боли в левой половине грудной клетки, наросла одышка, появилась слабость. `,
              block2: `При осмотре: АД 90/50 мм рт.ст., ЧСС 100-110 в мин, аритмичный пульс. 

                        <br>
                        При госпитализации NT – proBNP – 890 пг/мл `,
              block3: `
              На ЭКГ: Фибрилляция предсердий с частотой желудочковых сокращений 108-115 уд/мин. Неполная блокада правой ножки пучка Гиса. 
              `,
            },
            // Требуется двойная терапия (антиагрегант + антикоагулянт)
            test19_1_4: {
              block1: `Через несколько дней после коррекции терапи пациентка вызвала домой бригаду скорой медицинской помощи в связи с вновь возникшим частым аритмичным пульсов. Пациентка также отмечала колющие боли в левой половине грудной клетки, наросла одышка, появилась слабость. `,
              block2: `При осмотре: АД 90/50 мм рт.ст., ЧСС 100-110 в мин, аритмичный пульс. 
  
                    <br>
                        При госпитализации NT – proBNP – 890 пг/мл`,
              block3: `
             На ЭКГ: Фибрилляция предсердий с частотой желудочковых сокращений 108-115 уд/мин. Неполная блокада правой ножки пучка Гиса. 
              `,
            },
          },
        },
        // Оставить пропранолол по требованию
        test13_1_2: {
          // Продолжить прием Омега-3 кислот этиловый эфир
          test21_1_1: {
            // Не требуется
            test19_1_1: {
              block1: `На фоне выбранной терапевтической тактики у пациентки через 7 месяцев после  визита случился эмболический инсульт на фоне перенесенного пароксизма фибрилляции предсердий. При дообследовании было установлено, что причиной ОНМК послужила не рациональная антитромботическая терапия пациента с фибрилляцией предсердий (CHA<sub>2</sub>DS<sub>2</sub>&#8209;VASc – 4 балла – у пациентки исходно высокий риск эмболических осложнений, требовалось назначение антикоагулянтной терапии).`,
              block2: `При госпитализации: <br>
                  Липидный профиль:
                  <ul class="mt-4">
                  <li>Холестерин общий 4,7 ммоль/л</li>
                  <li>Триглицериды 1,62 ммоль/л</li>
                  <li>Холестерин ЛПОНП 0,87 ммоль/л</li>
                  <li>Холестерин ЛПНП 2,91 ммоль/л</li>
                  <li>Холестерин ЛПВП 0,92 ммоль/л</li>
                  </ul>
                `,

              block3: `
              <div class="mb-5">Дуплексное сканирование брахиоцефальных сосудов на экстракраниальном уровне
              <br>
              <b>Заключение:</b> Атеросклероз БЦА: стеноз ОСА справа до 30%, слева до 20%.</div>
              <div>
              ЭКГ - Фибрилляция предсердий с частотой желудочковых сокращений 108-115 уд/мин. Неполная блокада правой ножки пучка Гиса.   
              </div>
              `,
            },
            // Оральный антикоагулянт
            test19_1_2: {
              block1: `После коррекции терапи пациентка предъявляет жалобы на ангинозные боли при ускоренной ходьбе на 200 метров, на одышку при подъеме на 3 этаж, на ощущение кувыркания сердца в грудной клетке, периодически сопровождающееся колющим дискомфортом низкой интенсивности. Из-за перебоев в работе сердца уменьшила свою физическую активность. `,
              block2: `
                При осмотре: 
                АД 110/70 мм рт.ст., ЧСС 70–80 уд/мин, аритмичный пульс.
                <br>
                NT – proBNP – 760 пг/мл 
                <ul class="mt-4">
                <li>Липидный профиль через 6 месяцев:</li>
                <li>Холестерин общий 4,7 ммоль/л</li>
                <li>Триглицериды 1,62 ммоль/л</li>
                <li>Холестерин ЛПОНП 0,87 ммоль/л</li>
                <li>Холестерин ЛПНП 2,91 ммоль/л</li>
                <li>Холестерин ЛПВП 0,92 ммоль/л</li>
                </ul>
              `,
              block3: `
              <div class="mb-5">
              ЭКГ на повторном визите через 6 мес: Фибрилляция предсердий с частотой сердечных сокращений 72 уд/мин. Неполная блокада правой ножки пучка Гиса</div>
              <div>
              Дуплексное сканирование брахиоцефальных сосудов на экстракраниальном уровне
              <br>
              <b>Заключение:</b> Атеросклероз БЦА: стеноз ОСА справа до 30%, слева до 20%.</div>
              `,
            },
            // Антиагрегантная терапия
            test19_1_3: {
              block1: `На фоне выбранной терапевтической тактики у пациентки через 7 месяцев после  визита случился эмболический инсульт на фоне перенесенного пароксизма фибрилляции предсердий. При дообследовании было установлено, что причиной ОНМК послужила не рациональная антитромботическая терапия пациента с фибрилляцией предсердий (CHA<sub>2</sub>DS<sub>2</sub>&#8209;VASc – 4 балла – у пациентки исходно высокий риск эмболических осложнений, требовалось назначение антикоагулянтной терапии).`,
              block2: `При госпитализации: <br>
                  Липидный профиль:
                  <ul class="mt-4">
                  <li>Холестерин общий 4,7 ммоль/л</li>
                  <li>Триглицериды 1,62 ммоль/л</li>
                  <li>Холестерин ЛПОНП 0,87 ммоль/л</li>
                  <li>Холестерин ЛПНП 2,91 ммоль/л</li>
                  <li>Холестерин ЛПВП 0,92 ммоль/л</li>
                  </ul>
                `,

              block3: `
              <div class="mb-5">Дуплексное сканирование брахиоцефальных сосудов на экстракраниальном уровне
              <br>
              <b>Заключение:</b> Атеросклероз БЦА: стеноз ОСА справа до 30%, слева до 20%.</div>
              <div>
              ЭКГ - Фибрилляция предсердий с частотой желудочковых сокращений 108-115 уд/мин. Неполная блокада правой ножки пучка Гиса.   
              </div>
              `,
            },
            // Требуется двойная терапия (антиагрегант + антикоагулянт)
            test19_1_4: {
              block1: `После коррекции терапи пациентка предъявляет жалобы на беспокоят ангинозные боли при ускоренной ходьбе на 200 метров, одышку при подъеме на 3 этаж, на ощущение кувыркания сердца в грудной клетке, периодически сопровождающееся колющим дискомфортом низкой интенсивности. Из-за перебоев в работе сердца уменьшила свою физическую активность. На фоне принимаемой терапии антикоагулянт + антиагрегант также стала отмечать жалобы на изжогу, в связи с чем выполнили ФГДС и выявлена НПВС-гастропатия, эрозивный гастрит. `,
              block2: `При осмотре: 
                АД 110/70 мм рт.ст., ЧСС 68 уд/мин, ритмичный пульс.
                <br>
                Липидный профиль за 1 месяц до повторного визита к Вам:
                <ul class="mt-4">
                <li>Холестерин общий 4,7 ммоль/л</li>
                <li>Триглицериды 1,62 ммоль/л</li>
                <li>Холестерин ЛПОНП 0,87 ммоль/л</li>
                <li>Холестерин ЛПНП 2,91 ммоль/л</li>
                <li>Холестерин ЛПВП 0,92 ммоль/л</li>
                </ul>
                `,

              block3: `
              <div class="mb-5">ЭКГ за 1 месяц до повторного визита к Вам: Фибрилляция предсердий с частотой желудочковых сокращений 108-115 уд/мин. Неполная блокада правой ножки пучка Гиса.   </div>
              <div>
              Дуплексное сканирование брахиоцефальных сосудов на экстракраниальном уровне
              <br>
              <b>Заключение:</b> Атеросклероз БЦА: стеноз ОСА справа до 30%, слева до 20%.
              </div>
              `,
            },
          },
          // Аторвастатин
          test21_1_2: {
            // Не требуется
            test19_1_1: {
              block1: `На фоне выбранной терапевтической тактики у пациентки через 7 месяцев после визита случился эмболический инсульт на фоне перенесенного пароксизма фибрилляции предсердий. При дообследовании было установлено, что причиной ОНМК послужила не рациональная антитромботическая терапия пациента с фибрилляцией предсердий (CHA<sub>2</sub>DS<sub>2</sub>&#8209;VASc – 4 балла – у пациентки исходно высокий риск эмболических осложнений, требовалось назначение антикоагулянтной терапии).`,
              block2: `
                При госпитализации:
                <br>
                Липидограмма:
                <ul class="mt-4">
                <li>Холестерин общий 3,4 ммоль/л</li>
                <li>Триглицериды 1,72 ммоль/л</li>
                <li>Холестерин ЛПОНП 0,79 ммоль/л</li>
                <li>Холестерин ЛПНП 1,63 ммоль/л</li>
                <li>Холестерин ЛПВП 0,98 ммоль/л   </li>
                </ul>

              `,
              block3: `
              <div class="mb-5">Дуплексное сканирование брахиоцефальных сосудов на экстракраниальном уровне на момент госпитализации:<br>
              <b>Заключение:</b> Атеросклероз БЦА: стеноз правой ОСА до 25%.</div>
                            <div>

              ЭКГ - Фибрилляция предсердий с частотой желудочковых сокращений 108-115 уд/мин. Неполная блокада правой ножки пучка Гиса.   </div>
              `,
            },
            // Оральный антикоагулянт
            test19_1_2: {
              block1: `После коррекции терапи пациентка предъявляет жалобы на ангинозные боли при ускоренной ходьбе на 500 метров, на одышку при подъеме на 3 этаж, на ощущение "кувыркания" сердца в грудной клетке, периодически сопровождающееся колющим дискомфортом низкой интенсивности. Из-за перебоев в работе сердца уменьшила свою физическую активность. `,
              block2: `
              При осмотре через 3 месяца: 
              АД 110/70 мм рт.ст., ЧСС 70–80 уд/мин, аритмичный пульс.
              <br>
              NT – proBNP через 3 месяца – 760 пг/мл 
              <br>
              Липидограмма:
              <ul class="mt-4">
              <li>Холестерин общий 3,4 ммоль/л</li>
              <li>Триглицериды 1,72 ммоль/л</li>
              <li>Холестерин ЛПОНП 0,79 ммоль/л</li>
              <li>Холестерин ЛПНП 1,63 ммоль/л</li>
              <li>Холестерин ЛПВП 0,98 ммоль/л </li>
              </ul>

              `,
              block3: `
              <div class="mb-5">Дуплексное сканирование брахиоцефальных сосудов на экстракраниальном уровне. <br><b>Заключение:</b> Атеросклероз БЦА: стеноз правой ОСА до 25%.</div>
              <div>
              ЭКГ на повторном визите через 6 мес: Фибрилляция предсердий с частотой сердечных сокращений 72 уд/мин. Неполная блокада правой ножки пучка Гиса</div>
              `,
            },
            // Антиагрегантная терапия
            test19_1_3: {
              block1: `На фоне выбранной терапевтической тактики у пациентки через 7 месяцев после визита случился эмболический инсульт на фоне перенесенного пароксизма фибрилляции предсердий. При дообследовании было установлено, что причиной ОНМК послужила не рациональная антитромботическая терапия пациента с фибрилляцией предсердий (CHA<sub>2</sub>DS<sub>2</sub>&#8209;VASc – 4 балла – у пациентки исходно высокий риск эмболических осложнений, требовалось назначение антикоагулянтной терапии).`,
              block2: `
                При госпитализации:
                <br>
                Липидограмма:
                <ul class="mt-4">
                <li>Холестерин общий 3,4 ммоль/л</li>
                <li>Триглицериды 1,72 ммоль/л</li>
                <li>Холестерин ЛПОНП 0,79 ммоль/л</li>
                <li>Холестерин ЛПНП 1,63 ммоль/л</li>
                <li>Холестерин ЛПВП 0,98 ммоль/л   </li>
                </ul>

              `,
              block3: `
              <div class="mb-5">Дуплексное сканирование брахиоцефальных сосудов на экстракраниальном уровне на момент госпитализации:<br>
              <b>Заключение:</b> Атеросклероз БЦА: стеноз правой ОСА до 25%.</div>
                            <div>

              ЭКГ - Фибрилляция предсердий с частотой желудочковых сокращений 108-115 уд/мин. Неполная блокада правой ножки пучка Гиса.   </div>
              `,
            },
            // Требуется двойная терапия (антиагрегант + антикоагулянт)
            test19_1_4: {
              block1: `После коррекции терапи пациентка предъявляет жалобы на ангинозные боли при ускоренной ходьбе на 500 метров, одышку при подъеме на 3 этаж, на ощущение "кувыркания" сердца в грудной клетке. Из-за перебоев в работе сердца уменьшила свою физическую активность. На фоне принимаемой терапии антикоагулянт + антиагрегант также стала отмечать жалобы на изжогу, в связи с чем выполнили ФГДС и выявлена НПВС-гастропатия, эрозивный гастрит.`,
              block2: `
                При осмотре через 3 месяца:
                АД 110/70 мм рт.ст., ЧСС 70–80 уд/мин, аритмичный пульс.<br>
                NT – proBNP  – 760 пг/мл
                <br>
                Липидограмма:
                <ul class="mt-4">
                <li>Холестерин общий 3,4 ммоль/л</li>
                <li>Триглицериды 1,72 ммоль/л</li>
                <li>Холестерин ЛПОНП 0,79 ммоль/л</li>
                <li>Холестерин ЛПНП 1,63 ммоль/л</li>
                <li>Холестерин ЛПВП 0,98 ммоль/л</li>
                </ul>
              `,
              block3: `
              <div class="mb-5">ЭКГ: Фибрилляция предсердий с частотой сердечных сокращений 72 уд/мин. Неполная блокада правой ножки пучка Гиса</div>
              <div>Дуплексное сканирование брахиоцефальных сосудов на экстракраниальном уровне
              <br>
              <b>Заключение:</b> Атеросклероз БЦА: стеноз правой ОСА до 25%.</div>
              `,
            },
          },
          // Крестор
          test21_1_3: {
            // Не требуется
            test19_1_1: {
              block1: `На фоне выбранной терапевтической тактики у пациентки через 7 месяцев после  визита случился эмболический инсульт. При дообследовании было установлено, что причиной ОНМК послужила не рациональная антитромботическая терапия пациента с фибрилляцией предсердий (CHA<sub>2</sub>DS<sub>2</sub>&#8209;VASc – 4 балла – у пациентки исходно высокий риск эмболических осложнений, требовалось назначение антикоагулянтной терапии).`,
              block2: `
                Липидограмма при госпитализации:
                <ul class="mt-4">
                <li>Холестерин общий 2,81 ммоль/л</li>
                <li>Триглицериды 1,50 ммоль/л</li>
                <li>Холестерин ЛПОНП 0,40 ммоль/л</li>
                <li>Холестерин ЛПНП 1,36 ммоль/л</li>
                <li>Холестерин ЛПВП 1,05 ммоль/л</li>
                </ul>

              `,
              block3: `
              <div class="mb-5">Фибрилляция предсердий с частотой желудочковых сокращений 108-115 уд/мин. Неполная блокада правой ножки пучка Гиса.  </div>
              <div>         
              Дуплексное сканирование брахиоцефальных сосудов на экстракраниальном уровне. <br><b>Заключение:</b> Атеросклероз БЦА: стеноз правой ОСА до 20%.
              </div>
              `,
            },
            // Оральный антикоагулянт
            test19_1_2: {
              block1: `После коррекции терапи пациентка вновь обратилась через 6 месяцев, предъявляет жалобы на одышку при подъеме на 3 этаж, на ощущение "кувыркания" сердца в грудной клетке, периодически сопровождающееся колющим дискомфортом низкой интенсивности. Из-за перебоев в работе сердца уменьшила свою физическую активность. Продолжает рекомендованную терапию. `,
              block2: `
              При осмотре: АД 110/70 мм рт.ст., ЧСС 70–80 уд/мин, аритмичный пульс.
              <br>
              NT – proBNP – 760 пг/мл 
              <br>
              Липидограмма:
              <ul class="mt-4">
              <li>Холестерин общий 2,81 ммоль/л</li>
              <li>Триглицериды 1,50 ммоль/л</li>
              <li>Холестерин ЛПОНП 0,40 ммоль/л</li>
              <li>Холестерин ЛПНП 1,36 ммоль/л</li>
              <li>Холестерин ЛПВП 1,05 ммоль/л</li>
              </ul>

              `,
              block3: `
              <div class="mb-5">Фибрилляция предсердий с частотой желудочковых сокращений 108-115 уд/мин. Неполная блокада правой ножки пучка Гиса. </div>
              <div> 

              Дуплексное сканирование брахиоцефальных сосудов на экстракраниальном уровне. <br><b>Заключение:</b> Атеросклероз БЦА: стеноз правой ОСА до 20%.</div>
              `,
            },
            // Антиагрегантная терапия
            test19_1_3: {
              block1: `На фоне выбранной терапевтической тактики у пациентки через 7 месяцев после  визита случился эмболический инсульт. При дообследовании было установлено, что причиной ОНМК послужила не рациональная антитромботическая терапия пациента с фибрилляцией предсердий (CHA<sub>2</sub>DS<sub>2</sub>&#8209;VASc – 4 балла – у пациентки исходно высокий риск эмболических осложнений, требовалось назначение антикоагулянтной терапии).`,
              block2: `
                Липидограмма при госпитализации:
                <ul class="mt-4">
                <li>Холестерин общий 2,81 ммоль/л</li>
                <li>Триглицериды 1,50 ммоль/л</li>
                <li>Холестерин ЛПОНП 0,40 ммоль/л</li>
                <li>Холестерин ЛПНП 1,36 ммоль/л</li>
                <li>Холестерин ЛПВП 1,05 ммоль/л</li>
                </ul>

              `,
              block3: `
              <div class="mb-5">Фибрилляция предсердий с частотой желудочковых сокращений 108-115 уд/мин. Неполная блокада правой ножки пучка Гиса.  </div>
              <div>         
              Дуплексное сканирование брахиоцефальных сосудов на экстракраниальном уровне. <br><b>Заключение:</b> Атеросклероз БЦА: стеноз правой ОСА до 20%.
              </div>
              `,
            },
            // Требуется двойная терапия (антиагрегант + антикоагулянт)
            test19_1_4: {
              block1: `После коррекции терапи пациентка вновь обратилась через 6 месяцев, предъявляет жалобы на одышку при подъеме на 3 этаж, на ощущение "кувыркания" сердца в грудной клетке, периодически сопровождающееся колющим дискомфортом низкой интенсивности. Из-за перебоев в работе сердца уменьшила свою физическую активность. На фоне принимаемой терапии антикоагулянт + антиагрегант также стала отмечать жалобы на изжогу, в связи с чем выполнили ФГДС и выявлена НПВС-гастропатия, эрозивный гастрит.`,
              block2: `
              При осмотре: АД 110/70 мм рт.ст., ЧСС 70–80 уд/мин, аритмичный пульс.<br>
              NT – proBNP – 760 пг/мл
              <br>

              Липидограмма:
              <ul class="mt-4">
              <li>Холестерин общий 2,81 ммоль/л</li>
              <li>Триглицериды 1,50 ммоль/л</li>
              <li>Холестерин ЛПОНП 0,40 ммоль/л</li>
              <li>Холестерин ЛПНП 1,36 ммоль/л</li>
              <li>Холестерин ЛПВП 1,05 ммоль/л</li>
              </ul>

              `,
              block3: `
             <div class="mb-5">Фибрилляция предсердий с частотой желудочковых сокращений 108-115 уд/мин. Неполная блокада правой ножки пучка Гиса.  </div>
             <div>Дуплексное сканирование брахиоцефальных сосудов на экстракраниальном уровне. <br><b>Заключение:</b> Атеросклероз БЦА: стеноз правой ОСА до 20%.</div>
              `,
            },
          },
          // Эзетимиб
          test21_1_4: {
            // Не требуется
            test19_1_1: {
              block1: `На фоне выбранной терапевтической тактики у пациентки через 7 месяцев после  визита случился эмболический инсульт. При дообследовании было установлено, что причиной ОНМК послужила не рациональная антитромботическая терапия пациента с фибрилляцией предсердий (CHA<sub>2</sub>DS<sub>2</sub>&#8209;VASc – 4 балла – у пациентки исходно высокий риск эмболических осложнений, требовалось назначение антикоагулянтной терапии).`,
              block2: `
              При госпитализации: 
              <br>
              Липидограмма:
              <ul class="mt-4">
              <li>Холестерин общий 3,84 ммоль/л</li>
              <li>Триглицериды 1,82 ммоль/л</li>
              <li>Холестерин ЛПОНП 0,72 ммоль/л</li>
              <li>Холестерин ЛПНП  2,18 ммоль/л</li>
              <li>Холестерин ЛПВП 0,94 ммоль/л</li>
              </ul>

              `,
              block3: `
              <div class="mb-5">ЭКГ - Фибрилляция предсердий с частотой желудочковых сокращений 108-115 уд/мин. Неполная блокада правой ножки пучка Гиса. </div>
              <div>Дуплексное сканирование брахиоцефальных сосудов на экстракраниальном уровне. <br><b>Заключение:</b> Атеросклероз БЦА: стеноз правой ОСА до 25%.</div>
              `,
            },
            // Оральный антикоагулянт
            test19_1_2: {
              block1: `После коррекции терапи пациентка предъявляет жалобы на одышку при подъеме на 3 этаж, ангинозные боли при ускоренной ходьбе на 500 метров, ощущение "кувыркания" сердца в грудной клетке, периодически сопровождающееся колющим дискомфортом низкой интенсивности. Из-за перебоев в работе сердца уменьшила свою физическую активность.  `,
              block2: `
              При осмотре: АД 110/70 мм рт.ст., ЧСС 70–80 уд/мин, аритмичный пульс.
              <br>
              NT – proBNP через 3 месяца – 760 пг/мл 
              <br>
              Липидограмма через 6 месяцев:
              <ul class="mt-4">
              <li>Холестерин общий 3,84 ммоль/л</li>
              <li>Триглицериды 1,82 ммоль/л</li>
              <li>Холестерин ЛПОНП 0,72 ммоль/л</li>
              <li>Холестерин ЛПНП  2,18 ммоль/л</li>
              <li>Холестерин ЛПВП 0,94 ммоль/л</li>
              </ul>

              `,
              block3: `
              <div class="mb-5">Дуплексное сканирование брахиоцефальных сосудов на экстракраниальном уровне. <br><b>Заключение:</b> Атеросклероз БЦА: стеноз правой ОСА до 25%.</div>
              <div>Фибрилляция предсердий с частотой желудочковых сокращений 108-115 уд/мин. Неполная блокада правой ножки пучка Гиса.  </div>
              `,
            },
            // Антиагрегантная терапия
            test19_1_3: {
             block1: `На фоне выбранной терапевтической тактики у пациентки через 7 месяцев после  визита случился эмболический инсульт. При дообследовании было установлено, что причиной ОНМК послужила не рациональная антитромботическая терапия пациента с фибрилляцией предсердий (CHA<sub>2</sub>DS<sub>2</sub>&#8209;VASc – 4 балла – у пациентки исходно высокий риск эмболических осложнений, требовалось назначение антикоагулянтной терапии).`,
              block2: `
              При госпитализации: 
              <br>
              Липидограмма:
              <ul class="mt-4">
              <li>Холестерин общий 3,84 ммоль/л</li>
              <li>Триглицериды 1,82 ммоль/л</li>
              <li>Холестерин ЛПОНП 0,72 ммоль/л</li>
              <li>Холестерин ЛПНП  2,18 ммоль/л</li>
              <li>Холестерин ЛПВП 0,94 ммоль/л</li>
              </ul>

              `,
              block3: `
              <div class="mb-5">ЭКГ - Фибрилляция предсердий с частотой желудочковых сокращений 108-115 уд/мин. Неполная блокада правой ножки пучка Гиса. </div>
              <div>Дуплексное сканирование брахиоцефальных сосудов на экстракраниальном уровне. <br><b>Заключение:</b> Атеросклероз БЦА: стеноз правой ОСА до 25%.</div>
              `,
            },
            // Требуется двойная терапия (антиагрегант + антикоагулянт)
            test19_1_4: {
              block1: `После коррекции терапи пациентка предъявляет жалобы на беспокоят ангинозные боли при ускоренной ходьбе на 500 метров, одышку при подъеме на 3 этаж, на ощущение "кувыркания" сердца в грудной клетке, периодически сопровождающееся колющим дискомфортом низкой интенсивности. Из-за перебоев в работе сердца уменьшила свою физическую активность. На фоне принимаемой терапии антикоагулянт + антиагрегант также стала отмечать жалобы на изжогу, в связи с чем выполнили ФГДС и выявлена НПВС-гастропатия, эрозивный гастрит.`,
              block2: `При осмотре: АД 110/70 мм рт.ст., ЧСС 70–80 уд/мин, аритмичный пульс.
              <br>
              NT – proBNP через 3 месяца – 760 пг/мл.
              <br>
              Липидограмма через 6 месяцев:
              <ul class="mt-4">
              <li>Холестерин общий 3,84 ммоль/л</li>
              <li>Триглицериды 1,82 ммоль/л</li>
              <li>Холестерин ЛПОНП 0,72 ммоль/л</li>
              <li>Холестерин ЛПНП  2,18 ммоль/л</li>
              <li>Холестерин ЛПВП 0,94 ммоль/л</li>
              </ul>`,
              block3: `
              <div class="mb-5">ЭКГ - Фибрилляция предсердий с частотой желудочковых сокращений 108-115 уд/мин. Неполная блокада правой ножки пучка Гиса.  </div>
              <div>Дуплексное сканирование брахиоцефальных сосудов на экстракраниальном уровне. <br><b>Заключение:</b> Атеросклероз БЦА: стеноз правой ОСА до 25%.</div>
              `,
            },
          },
          // Эзетимиб + Статин
          test21_1_5: {
            // Не требуется
            test19_1_1: {
              block1: `На фоне выбранной терапевтической тактики у пациентки через 7 месяцев после  визита случился эмболический инсульт на фоне перенесенного пароксизма фибрилляции предсердий. При дообследовании было установлено, что причиной ОНМК послужила не рациональная антитромботическая терапия пациента с фибрилляцией предсердий (CHA<sub>2</sub>DS<sub>2</sub>&#8209;VASc – 4 балла – у пациентки исходно высокий риск эмболических осложнений, требовалось назначение антикоагулянтной терапии).`,
              block2: `
              При госпитализации липидограмма:
              <br>
              <ul class="mt-4">
              <li>Холестерин общий 2,54 ммоль/л</li>
              <li>Триглицериды 1,32 ммоль/л</li>
              <li>Холестерин ЛПОНП 0,28 ммоль/л</li>
              <li>Холестерин ЛПНП 1,04 ммоль/л</li>
              <li>Холестерин ЛПВП 1,22 ммоль/л</li>
              </ul>

              `,
              block3: `
              <div class="mb-5">
              При госпитализации:
              <br>
              Дуплексное сканирование брахиоцефальных сосудов на экстракраниальном уровне
              <br>
              <b>Заключение:</b> Атеросклероз БЦА, стеноз правой ОСА до 15%. 


              </div>
              <div>ЭКГ - Фибрилляция предсердий с частотой желудочковых сокращений 108-115 уд/мин. Неполная блокада правой ножки пучка Гиса.   </div>
              `,
            },
            // Оральный антикоагулянт
            test19_1_2: {
              block1: `После коррекции терапи пациентка предъявляет жалобы на одышку при подъеме на 3 этаж, на ощущение "кувыркания" сердца в грудной клетке, периодически сопровождающееся колющим дискомфортом низкой интенсивности. Из-за перебоев в работе сердца уменьшила свою физическую активность. Продолжает рекомендованную терапию. `,
              block2: `
              При осмотре: АД 110/70 мм рт.ст., ЧСС 70–80 уд/мин, аритмичный пульс.
                <br>
              NT – proBNP через 6 месяцев – 760 пг/мл    
              <br>     
              Липидограмма через 6 месяцев:
              <ul class="mt-4">
              <li>Холестерин общий 2,54 ммоль/л</li>
              <li>Триглицериды 1,32 ммоль/л</li>
              <li>Холестерин ЛПОНП 0,28 ммоль/л</li>
              <li>Холестерин ЛПНП 1,04 ммоль/л</li>
              <li>Холестерин ЛПВП 1,22 ммоль/л</li>
              </ul>           

              `,
              block3: `
              <div class="mb-5">ЭКГ при осмотре: Фибрилляция предсердий с частотой желудочковых сокращений 108-115 уд/мин. Неполная блокада правой ножки пучка Гиса.</div>
              <div>Дуплексное сканирование брахиоцефальных сосудов на экстракраниальном уровне
              <br>
              <b>Заключение:</b> Атеросклероз БЦА, стеноз правой ОСА до 15%.     </div>
              `,
            },
            // Антиагрегантная терапия
            test19_1_3: {
              block1: `На фоне выбранной терапевтической тактики у пациентки через 7 месяцев после  визита случился эмболический инсульт на фоне перенесенного пароксизма фибрилляции предсердий. При дообследовании было установлено, что причиной ОНМК послужила не рациональная антитромботическая терапия пациента с фибрилляцией предсердий (CHA<sub>2</sub>DS<sub>2</sub>&#8209;VASc – 4 балла – у пациентки исходно высокий риск эмболических осложнений, требовалось назначение антикоагулянтной терапии).`,
              block2: `
              При госпитализации липидограмма:
              <br>
              <ul class="mt-4">
              <li>Холестерин общий 2,54 ммоль/л</li>
              <li>Триглицериды 1,32 ммоль/л</li>
              <li>Холестерин ЛПОНП 0,28 ммоль/л</li>
              <li>Холестерин ЛПНП 1,04 ммоль/л</li>
              <li>Холестерин ЛПВП 1,22 ммоль/л</li>
              </ul>

              `,
              block3: `
              <div class="mb-5">
              При госпитализации:
              <br>
              Дуплексное сканирование брахиоцефальных сосудов на экстракраниальном уровне
              <br>
              <b>Заключение:</b> Атеросклероз БЦА, стеноз правой ОСА до 15%. 


              </div>
              <div>ЭКГ - Фибрилляция предсердий с частотой желудочковых сокращений 108-115 уд/мин. Неполная блокада правой ножки пучка Гиса.   </div>
              `,
            },
            // Требуется двойная терапия (антиагрегант + антикоагулянт)
            test19_1_4: {
              block1: `После коррекции терапи пациентка предъявляет жалобы на одышку при подъеме на 3 этаж, на ощущение "кувыркания" сердца в грудной клетке, периодически сопровождающееся колющим дискомфортом низкой интенсивности. Из-за перебоев в работе сердца уменьшила свою физическую активность. На фоне принимаемой терапии антикоагулянт + антиагрегант также стала отмечать жалобы на изжогу, в связи с чем выполнили ФГДС и выявлена НПВС-гастропатия, эрозивный гастрит.`,
              block2: `
              При осмотре через 6 месяцев:
              АД 110/70 мм рт.ст., ЧСС 70–80 уд/мин, аритмичный пульс.
              <br>
              NT – proBNP через 3 месяца – 760 пг/мл
              <br>
              Липидограмма через 6 месяцев:
              <ul class="mt-4 mb-4">
              <li>Холестерин общий 2,54 ммоль/л</li>
              <li>Триглицериды 1,32 ммоль/л</li>
              <li>Холестерин ЛПОНП 0,28 ммоль/л</li>
              <li>Холестерин ЛПНП 1,04 ммоль/л</li>
              <li>Холестерин ЛПВП 1,22 ммоль/л</li>
              </ul>
              

              `,
              block3: `
             <div class="mb-5">ЭКГ через 6 месяцев: Фибрилляция предсердий с частотой желудочковых сокращений 108-115 уд/мин. Неполная блокада правой ножки пучка Гиса.   </div>
             <div>Дуплексное сканирование брахиоцефальных сосудов на экстракраниальном уровне
             <br>
              <b>Заключение:</b> Атеросклероз БЦА, стеноз правой ОСА до 15%. </div>
              `,
            },
          },
        },
        // Назначить противорецидивирующую терапию (контроль ритма)
        test13_1_3: {
          // Продолжить прием Омега-3 кислот этиловый эфир
          test21_1_1: {
            // Не требуется
            test19_1_1: {
              block1: `На фоне выбранной терапевтической тактики у пациентки через 7 месяцев после визита случился эмболический инсульт на фоне перенесенного пароксизма фибрилляции предсердий. При дообследовании было установлено, что причиной ОНМК послужила не рациональная антитромботическая терапия пациента с фибрилляцией предсердий (CHA<sub>2</sub>DS<sub>2</sub>&#8209;VASc – 4 балла – у пациентки исходно высокий риск эмболических осложнений, требовалось назначение антикоагулянтной терапии).`,
              block2: `
              Липидный профиль при госпитализации:
              <ul class="mt-4">
              <li>Холестерин общий 4,7 ммоль/л</li>
              <li>Триглицериды 1,62 ммоль/л</li>
              <li>Холестерин ЛПОНП 0,87 ммоль/л</li>
              <li>Холестерин ЛПНП 2,91 ммоль/л</li>
              <li>Холестерин ЛПВП 0,92 ммоль/л</li>
              </ul>
              `,
              block3: `
              <div class="mb-5">Дуплексное сканирование брахиоцефальных сосудов на экстракраниальном уровне
              <br>
              <b>Заключение:</b> Атеросклероз БЦА: стеноз ОСА справа до 30%, слева до 20%.

              </div>
              <div>ЭКГ: P = 0.12, PQ = 0.20, QRS = 0.11, QRST = 0.46, RR = 0.98, ЧСС = 61, QRST корр. = 465; Синусовый ритм с ЧСС 61 уд/мин. Неполная блокада правой ножки п. Гиса</div>
              `,
            },
            // Оральный антикоагулянт
            test19_1_2: {
              block1: `После коррекции терапии жалобы на эпизоды нарушения ритма отрицает. Пациентка вновь обратилась через 6 месяцев с жалобами на ангинозные боли при ускоренной ходьбе на 200 метров. Одышка появляется при подъеме по лестнице на 3 этаже.  `,
              block2: `
              При осмотре через 6 месяцев:
              АД 120/70 мм рт.ст., ЧСС 60–65 уд/мин в покое. 
              <br>
              NT – proBNP – 290 пг/мл 
              <br>
              Липидный профиль:
              <ul class="mt-4">
              <li>Холестерин общий 4,7 ммоль/л</li>
              <li>Триглицериды 1,62 ммоль/л</li>
              <li>Холестерин ЛПОНП 0,87 ммоль/л</li>
              <li>Холестерин ЛПНП 2,91 ммоль/л</li>
              <li>Холестерин ЛПВП 0,92 ммоль/л</li>
              </ul>

              `,
              block3: `
              <div class="mb-5">Дуплексное сканирование брахиоцефальных сосудов на экстракраниальном уровне
              <br>
              <b>Заключение:</b> Атеросклероз БЦА: стеноз ОСА справа до 30%, слева до 20%.
              </div>
                            <div class="mb-5">ЭКГ: P = 0.12, PQ = 0.20, QRS = 0.11, QRST = 0.46, RR = 0.98, ЧСС = 61, QRST корр. = 465; Синусовый ритм с ЧСС 61 уд/мин. Неполная блокада правой ножки п. Гиса
              </div>
                            <div>НАГРУЗОЧНОЕ ТЕСТИРОВАНИЕ ЭКГ 
                            <br>
              Тип пробы: тредмил <br>
              Протокол: M-BRUCE <br>
              Ишемические изменения ЭКГ: горизонтальная депрессия сегмента ST: V3-6 до-1,5 мм <br>
              Метаболических единиц 6,1. Двойное произведение: 240.   <br>
              <b>Заключение:</b> Проба положительная по клиническим (дискомфорт за грудиной сжимающий) и ЭКГ-данным</div>
              `,
            },
            // Антиагрегантная терапия
            test19_1_3: {
              block1: `На фоне выбранной терапевтической тактики у пациентки через 7 месяцев после визита случился эмболический инсульт на фоне перенесенного пароксизма фибрилляции предсердий. При дообследовании было установлено, что причиной ОНМК послужила не рациональная антитромботическая терапия пациента с фибрилляцией предсердий (CHA<sub>2</sub>DS<sub>2</sub>&#8209;VASc – 4 балла – у пациентки исходно высокий риск эмболических осложнений, требовалось назначение антикоагулянтной терапии).`,
              block2: `
              Липидный профиль при госпитализации:
              <ul class="mt-4">
              <li>Холестерин общий 4,7 ммоль/л</li>
              <li>Триглицериды 1,62 ммоль/л</li>
              <li>Холестерин ЛПОНП 0,87 ммоль/л</li>
              <li>Холестерин ЛПНП 2,91 ммоль/л</li>
              <li>Холестерин ЛПВП 0,92 ммоль/л</li>
              </ul>
              `,
              block3: `
              <div class="mb-5">Дуплексное сканирование брахиоцефальных сосудов на экстракраниальном уровне
              <br>
              <b>Заключение:</b> Атеросклероз БЦА: стеноз ОСА справа до 30%, слева до 20%.

              </div>
              <div>ЭКГ: P = 0.12, PQ = 0.20, QRS = 0.11, QRST = 0.46, RR = 0.98, ЧСС = 61, QRST корр. = 465; Синусовый ритм с ЧСС 61 уд/мин. Неполная блокада правой ножки п. Гиса</div>
              `,
            },
            // Требуется двойная терапия (антиагрегант + антикоагулянт)
            test19_1_4: {
              block1: `После коррекции терапии жалобы на эпизоды нарушения ритма отрицает. Пациентка вновь обратилась через 7 месяцев с жалобами на ангинозные боли при ускоренной ходьбе на 200 метров. Одышка появляется при подъеме по лестнице на 3 этаже. Также на фоне проводимой терапии антикоагулянт + антиагрегант через 6 месяцев после  визита у пациентки появилась изжога, в связи с чем выполнили ФГДС, выявлена НПВС-гастропатия, эрозивный гастрит. Пациентке был отмен антиагрегант и оставлен только пероральный антикоагулянт, так как показаний для двойной терапии выявлено не было. `,
              block2: `
              При осмотре:
              АД 120/70 мм рт.ст., ЧСС 60–65 уд/мин в покое. 
              <br>
              NT – proBNP через 6 месяцев – 290 пг/мл.
              <br>
              Липидный профиль через 6 месяцев:
              <ul class="mt-4">
              <li>Холестерин общий 4,7 ммоль/л</li>
              <li>Триглицериды 1,62 ммоль/л</li>
              <li>Холестерин ЛПОНП 0,87 ммоль/л</li>
              <li>Холестерин ЛПНП 2,91 ммоль/л</li>
              <li>Холестерин ЛПВП 0,92 ммоль/л</li>
              </ul>

              `,
              block3: `
              <div class="mb-5">Дуплексное сканирование брахиоцефальных сосудов на экстракраниальном уровне
              <br>
              <b>Заключение:</b> Атеросклероз БЦА: стеноз ОСА справа до 30%, слева до 20%.</div>
              <div class="mb-5">ЭКГ: P = 0.12, PQ = 0.20, QRS = 0.11, QRST = 0.46, RR = 0.98, ЧСС = 61, QRST корр. = 465; Синусовый ритм с ЧСС 61 уд/мин. Неполная блокада правой ножки п. Гиса</div>
              <div>
              НАГРУЗОЧНОЕ ТЕСТИРОВАНИЕ ЭКГ
              <br>
              Тип пробы: тредмил <br>
              Протокол: M-BRUCE <br>
              Ишемические изменения ЭКГ: горизонтальная депрессия сегмента ST: V3-6 до-1,5 мм <br>
              Метаболических единиц 6,1. Двойное произведение: 240.  <br> 
              <b>Заключение:</b> Проба положительная по клиническим (дискомфорт за грудиной сжимающий) и ЭКГ-данным
              </div>
              `,
            },
          },
          // Аторвастатин
          test21_1_2: {
            // Не требуется
            test19_1_1: {
              block1: `На фоне выбранной терапевтической тактики у пациентки через 7 месяцев после визита случился эмболический инсульт на фоне перенесенного пароксизма фибрилляции предсердий. При дообследовании было установлено, что причиной ОНМК послужила не рациональная антитромботическая терапия пациента с фибрилляцией предсердий (CHA<sub>2</sub>DS<sub>2</sub>&#8209;VASc – 4 балла – у пациентки исходно высокий риск эмболических осложнений, требовалось назначение антикоагулянтной терапии).`,
              block2: `
              Дуплексное сканирование брахиоцефальных сосудов на экстракраниальном уровне на момент госпитализации:
              <br>
              <b>Заключение:</b> Атеросклероз БЦА: стеноз правой ОСА до 25%.
              <br>
              Липидограмма:
              <ul class="mt-4">
              <li>Холестерин общий 3,4 ммоль/л</li>
              <li>Триглицериды 1,72 ммоль/л</li>
              <li>Холестерин ЛПОНП 0,79 ммоль/л</li>
              <li>Холестерин ЛПНП 1,63 ммоль/л</li>
              <li>Холестерин ЛПВП 0,98 ммоль/л </li>
              </ul>
              `,
              block3: `
              <div class="mb-5">
              За 1 месяц до инсульта пациента проходила обследование (результаты на руках): 
              <br>
              Тип пробы: тредмил <br>
              Протокол: M-BRUCE <br>
              Ишемические изменения ЭКГ: горизонтальная депрессия сегмента ST: V4-6 до-1,5 мм <br>
              Метаболических единиц 7,1. Двойное произведение: 280.   <br>
              <b>Заключение:</b> Проба положительная по клиническим (дискомфорт за грудиной сжимающий) и ЭКГ-данным     
              </div>
              <div>ЭКГ: P = 0.12, PQ = 0.20, QRS = 0.11, QRST = 0.46, RR = 0.98, ЧСС = 61, QRST корр. = 465; Синусовый ритм с ЧСС 61 уд/мин. Неполная блокада правой ножки пучка Гиса</div>
              `,
            },
            // Оральный антикоагулянт
            test19_1_2: {
              block1: `После коррекции терапии жалобы на эпизоды нарушения ритма отрицает. Пациентка вновь обратилась к Вам спустя 6 месяцев с жалобами на ангинозные боли при ускоренной ходьбе на 500 метров. Одышка появляется на 3-4 этаже.`,
              block2: `
              При осмотре через 6 месяцев:
              АД 120/70 мм рт.ст., ЧСС 60–65 уд/мин в покое. 
              <br>
              NT – proBNP  – 290 пг/мл <br>
              Липидограмма:
              <ul class="mt-4">
              <li>Холестерин общий 3,4 ммоль/л</li>
              <li>Триглицериды 1,72 ммоль/л</li>
              <li>Холестерин ЛПОНП 0,79 ммоль/л</li>
              <li>Холестерин ЛПНП 1,63 ммоль/л</li>
              <li>Холестерин ЛПВП 0,98 ммоль/л</li>
              </ul>
              `,
              block3: `
              <div class="mb-5">Дуплексное сканирование брахиоцефальных сосудов на экстракраниальном уровне
              <b>Заключение:</b> Атеросклероз БЦА: стеноз правой ОСА до 25%.
              </div>
              <div class="mb-5">ЭКГ: P = 0.12, PQ = 0.20, QRS = 0.11, QRST = 0.46, RR = 0.98, ЧСС = 61, QRST корр. = 465; Синусовый ритм с ЧСС 61 уд/мин. Неполная блокада правой ножки п. Гиса</div>
              <div>
              Тип пробы: тредмил
              <br>
              Протокол: M-BRUCE  <br>
              Ишемические изменения ЭКГ: горизонтальная депрессия сегмента ST: V4-6 до-1,5 мм  <br>
              Метаболических единиц 7,1. Двойное произведение: 280.   <br> 
              <b>Заключение:</b> Проба положительная по клиническим (дискомфорт за грудиной сжимающий) и ЭКГ-данным
              </div>
              `,
            },
            // Антиагрегантная терапия
            test19_1_3: {
              block1: `На фоне выбранной терапевтической тактики у пациентки через 7 месяцев после визита случился эмболический инсульт на фоне перенесенного пароксизма фибрилляции предсердий. При дообследовании было установлено, что причиной ОНМК послужила не рациональная антитромботическая терапия пациента с фибрилляцией предсердий (CHA<sub>2</sub>DS<sub>2</sub>&#8209;VASc – 4 балла – у пациентки исходно высокий риск эмболических осложнений, требовалось назначение антикоагулянтной терапии).`,
              block2: `
              Дуплексное сканирование брахиоцефальных сосудов на экстракраниальном уровне на момент госпитализации:
              <br>
              <b>Заключение:</b> Атеросклероз БЦА: стеноз правой ОСА до 25%.
              <br>
              Липидограмма:
              <ul class="mt-4">
              <li>Холестерин общий 3,4 ммоль/л</li>
              <li>Триглицериды 1,72 ммоль/л</li>
              <li>Холестерин ЛПОНП 0,79 ммоль/л</li>
              <li>Холестерин ЛПНП 1,63 ммоль/л</li>
              <li>Холестерин ЛПВП 0,98 ммоль/л </li>
              </ul>
              `,
              block3: `
              <div class="mb-5">
              За 1 месяц до инсульта пациента проходила обследование (результаты на руках): 
              <br>
              Тип пробы: тредмил <br>
              Протокол: M-BRUCE <br>
              Ишемические изменения ЭКГ: горизонтальная депрессия сегмента ST: V4-6 до-1,5 мм <br>
              Метаболических единиц 7,1. Двойное произведение: 280.   <br>
              <b>Заключение:</b> Проба положительная по клиническим (дискомфорт за грудиной сжимающий) и ЭКГ-данным     
              </div>
              <div>ЭКГ: P = 0.12, PQ = 0.20, QRS = 0.11, QRST = 0.46, RR = 0.98, ЧСС = 61, QRST корр. = 465; Синусовый ритм с ЧСС 61 уд/мин. Неполная блокада правой ножки пучка Гиса</div>
              `,
            },
            // Требуется двойная терапия (антиагрегант + антикоагулянт)
            test19_1_4: {
              block1: `После коррекции терапии жалобы на эпизоды нарушения ритма отрицает. Пациентка вновь обратилась к Вам спустя 6 месяцев с жалобами на ангинозные боли при ускоренной ходьбе на 500 метров. Одышка появляется на 3-4 этаже. Также на фоне проводимой терапии антикоагулянт + антиагрегант у пациентки появилась изжога, в связи с чем выполнили ФГДС, выявлена НПВС-гастропатия, эрозивный гастрит. Пациентке был отмен антиагрегант и оставлен только пероральный антикоагулянт, так как показаний для двойной терапии выявлено не было. `,
              block2: `
              При осмотре:
              АД 120/70 мм рт.ст., ЧСС 60–65 уд/мин в покое. 
              <br>
              NT – proBNP  – 290 пг/мл.
              <br>
              Липидограмма:
              <ul class="mt-4">
              <li>Холестерин общий 3,4 ммоль/л</li>
              <li>Триглицериды 1,72 ммоль/л</li>
              <li>Холестерин ЛПОНП 0,79 ммоль/л</li>
              <li>Холестерин ЛПНП 1,63 ммоль/л</li>
              <li>Холестерин ЛПВП 0,98 ммоль/л</li>
              </ul>
              `,
              block3: `
              <div class="mb-5">
              Дуплексное сканирование брахиоцефальных сосудов на экстракраниальном уровне
              <br>
              <b>Заключение:</b> Атеросклероз БЦА: стеноз правой ОСА до 25%.
              </div>
              <div>
              Тип пробы: тредмил <br>
              Протокол: M-BRUCE <br>
              Ишемические изменения ЭКГ: горизонтальная депрессия сегмента ST: V4-6 до-1,5 мм <br>
              Метаболических единиц 7,1. Двойное произведение: 280.  <br>
              <b>Заключение:</b> Проба положительная по клиническим (дискомфорт за грудиной сжимающий) и ЭКГ-данным
              </div>
              `,
            },
          },
          // Крестор
          test21_1_3: {
            // Не требуется
            test19_1_1: {
              block1: `На фоне выбранной терапевтической тактики у пациентки через 7 месяцев после коррекции терапи случился эмболический инсульт. При дообследовании было установлено, что причиной ОНМК послужила не рациональная антитромботическая терапия пациента с фибрилляцией предсердий (CHA<sub>2</sub>DS<sub>2</sub>&#8209;VASc – 4 балла – у пациентки исходно высокий риск эмболических осложнений, требовалось назначение антикоагулянтной терапии). `,
              block2: `
              Липидограмма при госпитализации:
              <ul class="mt-4">
              <li>Холестерин общий 2,81 ммоль/л</li>
              <li>Триглицериды 1,50 ммоль/л</li>
              <li>Холестерин ЛПОНП 0,40 ммоль/л</li>
              <li>Холестерин ЛПНП 1,36 ммоль/л</li>
              <li>Холестерин ЛПВП 1,05 ммоль/л</li>
              </ul>
              `,
              block3: `
              <div>Дуплексное сканирование брахиоцефальных сосудов на экстракраниальном уровне
              <br>
              <b>Заключение:</b> Атеросклероз БЦА, стеноз правой ОСА до 20%.</div>
              `,
            },
            // Оральный антикоагулянт
            test19_1_2: {
              block1: `После коррекции терапии жалобы на эпизоды нарушения ритма отрицает. Переносимость физических нагрузок улучшилась, одышка появляется на 5 этаже. Продолжает рекомендованную терапию. Обратилась для профилактического осмотра. `,
              block2: `
              При осмотре: АД 120/70 мм рт.ст., ЧСС 60–65 уд/мин в покое. 
              <br>
              Липидограмма через 6 месяцев:
              <ul class="mt-4">
              <li>Холестерин общий 2,81 ммоль/л</li>
              <li>Триглицериды 1,50 ммоль/л</li>
              <li>Холестерин ЛПОНП 0,40 ммоль/л</li>
              <li>Холестерин ЛПНП 1,36 ммоль/л</li>
              <li>Холестерин ЛПВП 1,05 ммоль/л</li>
              </ul>

              `,
              block3: `
              <div class="mb-5">
              ЭКГ: P = 0.12, PQ = 0.20, QRS = 0.11, QRST = 0.46, RR = 0.98, ЧСС = 61, QRST корр. = 465; Синусовый ритм с ЧСС 61 уд/мин. Неполная блокада правой ножки п. Гиса
                        <br>                                                                     
              NT – proBNP через 6 месяцев – 290 пг/мл 
              </div>
              <div class="mb-5">Дуплексное сканирование брахиоцефальных сосудов на экстракраниальном уровне. <br><b>Заключение:</b> Атеросклероз БЦА: стеноз правой ОСА до 20%.</div>
              <div>
              НАГРУЗОЧНОЕ ТЕСТИРОВАНИЕ ЭКГ через 6 месяцев проводимой терапии. 
              <br>
              Тип пробы: тредмил <br>
              Протокол: M-BRUCE <br>
              <b>Заключение:</b> Тест отрицательный
              </div>
              `,
            },
            // Антиагрегантная терапия
            test19_1_3: {
              block1: `На фоне выбранной терапевтической тактики у пациентки через 7 месяцев после коррекции терапи случился эмболический инсульт. При дообследовании было установлено, что причиной ОНМК послужила не рациональная антитромботическая терапия пациента с фибрилляцией предсердий (CHA<sub>2</sub>DS<sub>2</sub>&#8209;VASc – 4 балла – у пациентки исходно высокий риск эмболических осложнений, требовалось назначение антикоагулянтной терапии). `,
              block2: `
              Липидограмма при госпитализации:
              <ul class="mt-4">
              <li>Холестерин общий 2,81 ммоль/л</li>
              <li>Триглицериды 1,50 ммоль/л</li>
              <li>Холестерин ЛПОНП 0,40 ммоль/л</li>
              <li>Холестерин ЛПНП 1,36 ммоль/л</li>
              <li>Холестерин ЛПВП 1,05 ммоль/л</li>
              </ul>
              `,
              block3: `
              <div>Дуплексное сканирование брахиоцефальных сосудов на экстракраниальном уровне
              <br>
              <b>Заключение:</b> Атеросклероз БЦА, стеноз правой ОСА до 20%.</div>
              `,
            },
            // Требуется двойная терапия (антиагрегант + антикоагулянт)
            test19_1_4: {
              block1: `
              <div class="mb-5">После коррекции терапи спустя 6 месяцев жалобы на эпизоды нарушения ритма отрицает. Переносимость физических нагрузок улучшилась, одышка появляется на 5 этаже при подъеме по лестнице. Продолжает прием рекомендованного лечения. 
              </div>
              <div>Однако фоне выбранной терапии антикоагулянт + антиагрегант через 7 месяцев после коррекции терапи у пациентки появилась изжога, в связи с чем выполнили ФГДС, выявлена НПВС-гастропатия, эрозивный гастрит. Пациентке был отмен антиагрегант и оставлен только пероральный антикоагулянт, так как показаний для двойной терапии выявлено не было. </div>
              `,
              block2: `
              При осмотре: АД 120/70 мм рт.ст., ЧСС 60–65 уд/мин в покое. 
              <br>
              NT – proBNP через 6 месяцев – 290 пг/мл.
              <br>
              Липидограмма через 6 месяцев:
              <ul class="mt-4">
              <li>Холестерин общий 2,81 ммоль/л</li>
              <li>Триглицериды 1,50 ммоль/л</li>
              <li>Холестерин ЛПОНП 0,40 ммоль/л</li>
              <li>Холестерин ЛПНП 1,36 ммоль/л</li>
              <li>Холестерин ЛПВП 1,05 ммоль/л  </li>
              </ul> 
      
              `,
              block3: `
              <div class="mb-5">
              Дуплексное сканирование брахиоцефальных сосудов на экстракраниальном уровне
              <br>
              <b>Заключение:</b> Атеросклероз БЦА: стеноз правой ОСА до 20%.
              </div>
              <div class="mb-5">ЭКГ через 1 месяц: P = 0.12, PQ = 0.20, QRS = 0.11, QRST = 0.46, RR = 0.98, ЧСС = 61, QRST корр. = 465; Синусовый ритм с ЧСС 61 уд/мин. Неполная блокада правой ножки пучка Гиса</div>
              <div>
              НАГРУЗОЧНОЕ ТЕСТИРОВАНИЕ ЭКГ через 6 месяцев проводимой терапии. 
              <br>
              Тип пробы: тредмил <br>
              Протокол: M-BRUCE <br>
              <b>Заключение:</b> Тест отрицательный
              </div>
              `,
            },
          },
          // Эзетимиб
          test21_1_4: {
            // Не требуется
            test19_1_1: {
              block1: `На фоне выбранной терапевтической тактики у пациентки через 7 месяцев после  визита случился эмболический инсульт. При дообследовании было установлено, что причиной ОНМК послужила не рациональная антитромботическая терапия пациента с фибрилляцией предсердий (CHA<sub>2</sub>DS<sub>2</sub>&#8209;VASc – 4 балла – у пациентки исходно высокий риск эмболических осложнений, требовалось назначение антикоагулянтной терапии).`,
              block2: `
              При госпитализации: 
              <br>
              Липидограмма:
              <ul class="mt-4">
              <li>Холестерин общий 3,84 ммоль/л</li>
              <li>Триглицериды 1,82 ммоль/л</li>
              <li>Холестерин ЛПОНП 0,72 ммоль/л</li>
              <li>Холестерин ЛПНП  2,18 ммоль/л</li>
              <li>Холестерин ЛПВП 0,94 ммоль/л</li>
              </ul>
              `,
              block3: `
              Дуплексное сканирование брахиоцефальных сосудов на экстракраниальном уровне. <b>Заключение:</b> Атеросклероз БЦА: стеноз правой ОСА до 25%.
              `,
            },
            // Оральный антикоагулянт
            test19_1_2: {
              block1: `После коррекции терапи жалобы на эпизоды нарушения ритма отрицает. Пациентка вновь обратилась спустя 6 месяцев с жалобами на ангинозные боли при ускоренной ходьбе на 500 метров. Одышка появляется на 3-4 этаже.`,
              block2: `
              При осмотре: АД 120/70 мм рт.ст., ЧСС 60–65 уд/мин в покое. <br>
              NT – proBNP – 290 пг/мл 
              <br>
              Липидограмма через 6 месяцев:
              <ul class="mt-4">
              <li>Холестерин общий 3,84 ммоль/л</li>
              <li>Триглицериды 1,82 ммоль/л</li>
              <li>Холестерин ЛПОНП 0,72 ммоль/л</li>
              <li>Холестерин ЛПНП  2,18 ммоль/л</li>
              <li>Холестерин ЛПВП 0,94 ммоль/л</li>
              </ul>
              `,
              block3: `
              <div class="mb-5">Дуплексное сканирование брахиоцефальных сосудов на экстракраниальном уровне. <br><b>Заключение:</b> Атеросклероз БЦА: стеноз правой ОСА до 25%.</div>
              <div class="mb-5">ЭКГ: P = 0.12, PQ = 0.20, QRS = 0.11, QRST = 0.46, RR = 0.98, ЧСС = 61, QRST корр. = 465; Синусовый ритм с ЧСС 61 уд/мин. Неполная блокада правой ножки п. Гиса</div>
              <div>
              НАГРУЗОЧНОЕ ТЕСТИРОВАНИЕ ЭКГ через 6 месяцев проводимой терапии
              <br>
              Тип пробы: тредмил  <br>
              Протокол: M-BRUCE  <br>
              Ишемические изменения ЭКГ: горизонтальная депрессия сегмента ST: V4-6 до-1,0 мм  <br>
              Метаболических единиц 7,2. Двойное произведение: 285.   <br> 
              <b>Заключение:</b> Проба положительная по клиническим (дискомфорт за грудиной сжимающий) и ЭКГ-данным
              </div>
              `,
            },
            // Антиагрегантная терапия
            test19_1_3: {
              block1: `На фоне выбранной терапевтической тактики у пациентки через 7 месяцев после  визита случился эмболический инсульт. При дообследовании было установлено, что причиной ОНМК послужила не рациональная антитромботическая терапия пациента с фибрилляцией предсердий (CHA<sub>2</sub>DS<sub>2</sub>&#8209;VASc – 4 балла – у пациентки исходно высокий риск эмболических осложнений, требовалось назначение антикоагулянтной терапии).`,
              block2: `
              При госпитализации: 
              <br>
              Липидограмма:
              <ul class="mt-4">
              <li>Холестерин общий 3,84 ммоль/л</li>
              <li>Триглицериды 1,82 ммоль/л</li>
              <li>Холестерин ЛПОНП 0,72 ммоль/л</li>
              <li>Холестерин ЛПНП  2,18 ммоль/л</li>
              <li>Холестерин ЛПВП 0,94 ммоль/л</li>
              </ul>
              `,
              block3: `
              Дуплексное сканирование брахиоцефальных сосудов на экстракраниальном уровне. <b>Заключение:</b> Атеросклероз БЦА: стеноз правой ОСА до 25%.
              `,
            },
            // Требуется двойная терапия (антиагрегант + антикоагулянт)
            test19_1_4: {
              block1: `После коррекции терапи нарушения ритма отрицает. Пациентка вновь обратилась спустя 6 месяцев с жалобами на ангинозные боли при ускоренной ходьбе на 500 метров. Одышка появляется на 3-4 этаже. На фоне выбранной терапии антикоагулянт + антиагрегант через 5 месяцев после визита у пациентки появилась изжога, в связи с чем выполнили ФГДС, выявлена НПВС-гастропатия, эрозивный гастрит. Пациентке был отмен антиагрегант и оставлен только пероральный антикоагулянт, так как показаний для двойной терапии выявлено не было. `,
              block2: `
              При осмотре: АД 120/70 мм рт.ст., ЧСС 60–65 уд/мин в покое. 
              <br>
              NT – proBNP – 290 пг/мл.
              <br>
              Липидограмма через 6 месяцев:
              <ul class="mt-4">
              <li>Холестерин общий 3,84 ммоль/л</li>
              <li>Триглицериды 1,82 ммоль/л</li>
              <li>Холестерин ЛПОНП 0,72 ммоль/л</li>
              <li>Холестерин ЛПНП  2,18 ммоль/л</li>
              <li>Холестерин ЛПВП 0,94 ммоль/л</li>
              </ul>

              `,
              block3: `
              <div class="mb-5">
              Дуплексное сканирование брахиоцефальных сосудов на экстракраниальном уровне. <br><b>Заключение:</b> Атеросклероз БЦА: стеноз правой ОСА до 25%.
              </div>
              <div class="mb-5">ЭКГ: P = 0.12, PQ = 0.20, QRS = 0.11, QRST = 0.46, RR = 0.98, ЧСС = 61, QRST корр. = 465; Синусовый ритм с ЧСС 61 уд/мин. Неполная блокада правой ножки п. Гиса</div>
              <div>
              НАГРУЗОЧНОЕ ТЕСТИРОВАНИЕ ЭКГ через 6 месяцев проводимой терапии
              <br>
              Тип пробы: тредмил <br>
              Протокол: M-BRUCE <br>
              Ишемические изменения ЭКГ: горизонтальная депрессия сегмента ST: V4-6 до-1,0 мм <br>
              Метаболических единиц 7,2. Двойное произведение: 285.   <br>
              <b>Заключение:</b> Проба положительная по клиническим (дискомфорт за грудиной сжимающий) и ЭКГ-данным
              </div>
              `,
            },
          },
          // Эзетимиб + Статин
          test21_1_5: {
            // Не требуется
            test19_1_1: {
              block1: `На фоне выбранной терапевтической тактики у пациентки через 7 месяцев после коррекции терапи случился эмболический инсульт на фоне перенесенного пароксизма фибрилляции предсердий. При дообследовании было установлено, что причиной ОНМК послужила не рациональная антитромботическая терапия (CHA<sub>2</sub>DS<sub>2</sub>&#8209;VASc – 4 балла – у пациентки исходно высокий риск эмболических осложнений, требовалось назначение антикоагулянтной терапии). `,
              block2: `
              При госпитализации липидограмма:
              <ul class="mt-4">
              <li>Холестерин общий 2,54 ммоль/л</li>
              <li>Триглицериды 1,32 ммоль/л</li>
              <li>Холестерин ЛПОНП 0,28 ммоль/л</li>
              <li>Холестерин ЛПНП 1,04 ммоль/л</li>
              <li>Холестерин ЛПВП 1,22 ммоль/л</li>
              </ul>
              `,
              block3: `
              <div class="mb-5">
              При госпитализации: 
              <br>
              Дуплексное сканирование брахиоцефальных сосудов на экстракраниальном уровне <br>
              <b>Заключение:</b> Атеросклероз БЦА, стеноз правой ОСА до 15%.
              </div>
              <div>
              ЭКГ: P = 0.12, PQ = 0.20, QRS = 0.11, QRST = 0.46, RR = 0.98, ЧСС = 61, QRST корр. = 465; Синусовый ритм с ЧСС 61 уд/мин. Неполная блокада правой ножки п. Гиса
              </div>
              `,
            },
            // Оральный антикоагулянт
            test19_1_2: {
              block1: `После коррекции терапии жалобы на эпизоды нарушения ритма отрицает. Переносимость физических нагрузок улучшилась, одышка появляется на 5 этаже. Продолжает рекомендованную терапию. `,
              block2: `
              При осмотре через 6 месяцев: 
              АД 120/70 мм рт.ст., ЧСС 60–65 уд/мин в покое. 
              <br>
              NT – proBNP через 6 месяцев – 290 пг/мл 
              <br>
              Липидограмма через 6 месяцев:
              <ul class="mt-4">
              <li>Холестерин общий 2,54 ммоль/л</li>
              <li>Триглицериды 1,32 ммоль/л</li>
              <li>Холестерин ЛПОНП 0,28 ммоль/л</li>
              <li>Холестерин ЛПНП 1,04 ммоль/л</li>
              <li>Холестерин ЛПВП 1,22 ммоль/л</li>
              </ul>
              `,
              block3: `
              <div class="mb-5">Дуплексное сканирование брахиоцефальных сосудов на экстракраниальном уровне
              <br>
              <b>Заключение:</b> Атеросклероз БЦА: стеноз правой ОСА до 15%.</div>
              <div class="mb-5">ЭКГ через 6 месяцев: P = 0.12, PQ = 0.20, QRS = 0.11, QRST = 0.46, RR = 0.98, ЧСС = 61, QRST корр. = 465; Синусовый ритм с ЧСС 61 уд/мин. Неполная блокада правой ножки пучка Гиса</div>
              <div>
              НАГРУЗОЧНОЕ ТЕСТИРОВАНИЕ ЭКГ через 6 месяцев <br>
              Тип пробы: тредмил <br>
              Протокол: M-BRUCE <br>
              <b>Заключение:</b> Тест отрицательный
              </div>
              `,
            },
            // Антиагрегантная терапия
            test19_1_3: {
              block1: `На фоне выбранной терапевтической тактики у пациентки через 7 месяцев после коррекции терапи случился эмболический инсульт на фоне перенесенного пароксизма фибрилляции предсердий. При дообследовании было установлено, что причиной ОНМК послужила не рациональная антитромботическая терапия (CHA<sub>2</sub>DS<sub>2</sub>&#8209;VASc – 4 балла – у пациентки исходно высокий риск эмболических осложнений, требовалось назначение антикоагулянтной терапии). `,
              block2: `
              При госпитализации липидограмма:
              <ul class="mt-4">
              <li>Холестерин общий 2,54 ммоль/л</li>
              <li>Триглицериды 1,32 ммоль/л</li>
              <li>Холестерин ЛПОНП 0,28 ммоль/л</li>
              <li>Холестерин ЛПНП 1,04 ммоль/л</li>
              <li>Холестерин ЛПВП 1,22 ммоль/л</li>
              </ul>
              `,
              block3: `
              <div class="mb-5">
              При госпитализации: 
              <br>
              Дуплексное сканирование брахиоцефальных сосудов на экстракраниальном уровне <br>
              <b>Заключение:</b> Атеросклероз БЦА, стеноз правой ОСА до 15%.
              </div>
              <div>
              ЭКГ: P = 0.12, PQ = 0.20, QRS = 0.11, QRST = 0.46, RR = 0.98, ЧСС = 61, QRST корр. = 465; Синусовый ритм с ЧСС 61 уд/мин. Неполная блокада правой ножки п. Гиса
              </div>
              `,
            },
            // Требуется двойная терапия (антиагрегант + антикоагулянт)
            test19_1_4: {
              block1: `После коррекции терапи спустя 6 месяцев жалобы на эпизоды нарушения ритма отрицает. Переносимость физических нагрузок улучшилась, одышка появляется на 5 этаже при подъеме по лестнице. Продолжает прием рекомендованного лечения. 
              <br>
              На фоне выбранной терапии антикоагулянт + антиагрегант через 7 месяцев после коррекции терапи у пациентки появилась изжога, в связи с чем выполнили ФГДС, выявлена НПВС-гастропатия, эрозивный гастрит. Пациентке был отмен антиагрегант и оставлен только пероральный антикоагулянт, так как показаний для двойной терапии выявлено не было. `,
              block2: `
              При осмотре через 7 месяцев:
              АД 120/70 мм рт.ст., ЧСС 60–65 уд/мин в покое. 
              <br>
              NT – proBNP – 290 пг/мл.
              <br>
              Липидограмма через 7 месяцев:
              <ul class="">
              <li>Холестерин общий 2,54 ммоль/л</li>
              <li>Триглицериды 1,32 ммоль/л</li>
              <li>Холестерин ЛПОНП 0,28 ммоль/л</li>
              <li>Холестерин ЛПНП 1,04 ммоль/л</li>
              <li>Холестерин ЛПВП 1,22 ммоль/л</li>
              </ul>
              `,
              block3: `
              <div class="mb-5">
              Дуплексное сканирование брахиоцефальных сосудов на экстракраниальном уровне
              <br>
              <b>Заключение:</b> Атеросклероз БЦА: стеноз правой ОСА до 15%.
              </div>
              <div class="mb-5">ЭКГ: P = 0.12, PQ = 0.20, QRS = 0.11, QRST = 0.46, RR = 0.98, ЧСС = 61, QRST корр. = 465; Синусовый ритм с ЧСС 61 уд/мин. Неполная блокада правой ножки пучка Гиса</div>
              <div>
              НАГРУЗОЧНОЕ ТЕСТИРОВАНИЕ ЭКГ через 7 месяцев
              <br>
              Тип пробы: тредмил <br>
              Протокол: M-BRUCE <br>
              <b>Заключение:</b> Тест отрицательный
              </div>
              `,
            },
          },
        },
      };
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/icc_detail.scss";
</style>